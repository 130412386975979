div.leftSideBar {
  z-index: 2;
  width: 16%;
  padding: 10px;
  height: 100%;
  position: fixed;
  text-align: left;
  background: #4fbdcb;
  top: 45px;
}
div.leftSideBar section.sidebarSection {
  cursor: pointer;
}
div.leftSideBar section.sidebarSection div.sidebarLink {
  height: 40px;
  font-size: 14px;
  border-radius: 2px;
}
div.leftSideBar section.sidebarSection div.sidebarLink a {
  padding-left: 15px;
  padding-top: 10px;
  display: block;
  color: #4fbdcb;
  height: 100%;
  text-align: left;
  text-decoration: none;
}
div.leftSideBar section.sidebarSection div.sidebarLink.active {
  background-color: #eaecef;
}
div.leftSideBar section.sidebarSection div.sidebarLink.active a {
  color: #4fbdcb;
}
div.leftSideBar section.sidebarSection div.sidebarLink:hover {
  background-color: #eeeff2;
}
div.leftSideBar section.sidebarSection div.sidebarLink:hover a {
  color: #4fbdcb;
}

div.link.item{
  background: #F0F8FF !important;
}

div.link.item:hover{
  background: #c4ccd3 !important;
}

div.ui.item.dropdown{
  background: #F0F8FF !important;
}

div.ui.item.dropdown:hover{
  background: #c4ccd3 !important;
}

ant-menu.ant-menu-dark{
  background: #1fbb64;
}


/*# sourceMappingURL=LeftSideBar.css.map */
